<template>
  <component
    class="relative"
    :is="layoutComponent"
    :show-timeline="showTimeline"
    :show-left="showTree"
    :show-right="showInspector"
    @grid-resized="onGridResized"
    @dragenter.prevent.stop="onDragEnter"
    @dragover.prevent.stop="onDragOver"
    @dragleave.prevent.stop="onDragLeave"
    @drop.prevent.stop="onDrop"
  >

<!--
  <template v-slot:inspector v-if="viewer">
    <div v-show="inspectorVisible" class="absolute top-0 right-0 h-full mx-4 my-4 overflow-y-auto rounded-md pointer-events-auto flex-0 w-72">
      <div class="relative flex-1 h-full overflow-y-auto text-white transition-all bg-gray-800 sidebar-content">
        <ItemInspector
          :viewer="viewer"
          @settings-changed="$emit('settings-changed', $event)"
          @scene-changed="$emit('scene-changed', $event)"
        />
      </div>
    </div>
  </template>

  <template v-slot:viewer>
    <SceneContainer
        v-if="asset && sequence && resources"
        :asset="asset"
        :sequence="sequence"
        :resources="resources"
        :play-mode="playMode"
      />
  </template>

    <template v-slot:left>
      <div
        class="relative flex flex-col flex-1 w-full h-full overflow-hidden"
        @drop.prevent.stop="onDrop"
      >
        <template v-if="viewer">
          <div class="flex w-full h-full overflow-hidden">
            <div class="w-full overflow-y-auto pointer-events-auto">
              <div class="h-full overflow-y-auto text-white transition-all bg-gray-800 sidebar-content">
                <ResourcesTree :viewer="viewer" />
              </div>
            </div>
          </div>
        </template>
      </div>
      <DraggingToast v-if="isDragging" />
    </template> -->

    <template v-slot:viewer>
      <div
        class="relative flex flex-col flex-1 w-full h-full overflow-hidden"
        @drop.prevent.stop="onDrop"
      >
        <template v-if="viewer">
          <div
            class="absolute top-0 left-0 z-40 w-full h-full pointer-events-none"
            v-show="!playMode"
          >
            <div
              class="relative flex flex-col justify-between flex-1 w-full h-full"
            >
              <div class="flex justify-between w-full h-full overflow-hidden">
                <div
                  class="overflow-y-auto pointer-events-auto flex-0 w-72"
                  :class="{ 'm-4 rounded-md': panelPadding }"
                >
                  <div
                    class="relative flex-1 h-full overflow-y-auto text-white transition-all bg-gray-800 sidebar-content"
                  >
                    <ResourcesTree :viewer="viewer" @settings-changed="$emit('settings-changed', $event)" />
                  </div>
                </div>
                <div
                  class="h-full py-4 mx-4 overflow-y-auto rounded-md pointer-events-auto flex-0 w-72"
                  v-show="inspectorVisible"
                >
                  <div
                    class="relative flex-1 h-full overflow-y-auto text-white transition-all bg-gray-800 sidebar-content"
                  >
                    <ItemInspector
                      :viewer="viewer"
                      @settings-changed="$emit('settings-changed', $event)"
                      @scene-changed="$emit('scene-changed', $event)"
                    />
                  </div>
                </div>
              </div>
              <!-- <div
                class="overflow-y-auto pointer-events-auto"
                :class="{ 'm-4 rounded-md': panelPadding }"
              >
                <div class="w-full h-1 bg-gray-500 cursor-grab"></div>
                <div
                  class="relative flex-1 h-full overflow-y-auto text-white transition-all bg-gray-800"
                >
                  <TimelineBar
                    style="height: 400px"
                    v-if="viewer"
                    :viewer="viewer"
                    @timeline-changed="$emit('timeline-changed', $event)"
                  />
                </div>
              </div> -->
            </div>
          </div>
        </template>

        <SceneContainer
          v-if="asset && sequence && resources"
          :asset="asset"
          :sequence="sequence"
          :resources="resources"
          :play-mode="playMode"
        />
      </div>
      <DraggingToast v-if="isDragging" />
    </template>

    <template v-slot:timeline>
      <div class="relative flex flex-col flex-1 w-full h-full overflow-hidden">
        <div
          class="relative flex-1 h-full overflow-y-auto bg-gray-800 timeline-content"
        >
          <TimelineBar
            v-if="viewer"
            :viewer="viewer"
            @timeline-changed="$emit('timeline-changed', $event)"
          />
        </div>
      </div>
    </template>
  </component>
</template>
<script>
const SceneContainer = () => import('@components/viewer/SceneContainer')
const SequenceContainer = () => import('@components/viewer/SequenceContainer')
const DraggingToast = () => import('@components/editor/DraggingToast')
const LayoutPanels = () => import('@components/splitgrid/layouts/LayoutPanels')
const LayoutPanelsB = () => import('@components/splitgrid/layouts/LayoutPanelsB')
const EditorPanels = () => import('@components/editor/EditorPanels')
const WorldPanel = () => import('@components/editor/scene/panels/WorldPanel')
const ItemInspector = () =>
  import('@components/editor/scene/panels/ItemInspector')
const TimelineBar = () => import('@components/editor/scene/panels/TimelineBar')
const TimelineTopBar = () =>
  import('@components/editor/scene/panels/TimelineTopBar')
const ResourcesTree = () =>
  import('@components/editor/scene/panels/ResourcesTree')

export default {
  components: {
    LayoutPanels,
    LayoutPanelsB,
    SceneContainer,
    SequenceContainer,
    WorldPanel,
    ItemInspector,
    TimelineBar,
    DraggingToast,
    TimelineTopBar,
    EditorPanels,
    ResourcesTree
  },
  props: {
    viewer: {
      type: Object,
      default: null
    },

    context: {
      type: String,
      default: 'editor'
    },

    asset: {
      type: Object,
      required: true
    },

    sequence: {
      type: Object,
      default: null
    },

    resources: {
      type: Array,
      default: null
    },

    playMode: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isDragging: false,
      editorVisible: true,
      showEditorUI: true,
      panelPadding: false,

      showTree: true,
      // showTimeline: false,
      showInspector: true,
      inspectorVisible: false,
      showResourcesPanel: true
    }
  },

  computed: {
    needsReview() {
      return this.asset.status === 'review'
    },

    showTimeline() {
      return this.hasTimeline !== null
    },

    hasTimeline() {
      return (
        this.viewer &&
        this.viewer.core &&
        this.viewer.core.sequence &&
        this.viewer.core.sequence.timeline
      )
    },

    layoutComponent() {
      // return 'LayoutPanelsB'
      return 'LayoutPanels'
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.onGridResized()
    })

    this.attachKeyboardListeners()
    this.attachEventListeners()
  },

  beforeDestroy() {
    this.dettachKeyboardListeners()
  },

  methods: {
    toggleMenu(type) {
      if (type === 'resources') {
        this.showResourcesPanel = !this.showResourcesPanel
      }
    },

    triggerFullSaveModal() {
      this.$bus.$emit('scene:model:require-full-save')
    },

    attachEventListeners() {
      this.$bus.$on('editor:panel:toggle', this.toggleView)
      this.$bus.$on('editor:panel:toggle.inspector', this.toggleInspector)
    },

    toggleView() {
      this.showTree = !this.showTree
      this.showInspector = !this.showInspector
    },

    toggleInspector(active) {
      this.inspectorVisible = active
    },

    attachKeyboardListeners() {
      document.addEventListener('keyup', this.handleKeyboardEvents)
    },

    dettachKeyboardListeners() {
      document.removeEventListener('keyup', this.handleKeyboardEvents)
    },

    handleKeyboardEvents(event) {
      let activeElementType = document.activeElement.nodeName

      // We only listen for input if the activeElement is NOT an input, or text area
      if (
        !(activeElementType === 'INPUT' || activeElementType === 'TEXTAREA')
      ) {
        switch (event.code) {
          case 'Slash':
            this.toggleEditorUI()
            break
        }
      }
    },

    toggleEditorUI() {
      this.editorVisible = !this.editorVisible
    },

    onDrop(event) {
      this.isDragging = false
      this.handleFileDrop(event.dataTransfer.items)
    },

    onDragEnter(event) {
      this.isDragging = true
    },

    onDragLeave(event) {
      this.isDragging = false
    },

    onDragOver(event) {},

    handleFileDrop(items) {
      const handle = (file, path) => {
        if (file.name.startsWith('.')) return
        this.$bus.$emit('scene:model:import', file)
      }

      const traverse = (item, path) => {
        path = path || ''

        if (item.isFile) {
          item.file((file) => {
            handle(file, path)
          })
        }

        if (item.isDirectory) {
          let dirReader = item.createReader()
          dirReader.readEntries(function (entries) {
            Array.from(entries).forEach((child) => {
              traverse(child, path + item.name + '/')
            })
          })
        }
      }

      Array.from(items).forEach((item) => {
        item = item.webkitGetAsEntry()
        if (item) {
          traverse(item)
        }
      })
    },

    onGridResized() {
      // Spoof a window resize event
      window.dispatchEvent(new Event('resize'))
    }
  }
}
</script>
